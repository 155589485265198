import { render, staticRenderFns } from "./recordList.vue?vue&type=template&id=5037772c&scoped=true&"
import script from "./recordList.vue?vue&type=script&lang=js&"
export * from "./recordList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5037772c",
  null
  
)

export default component.exports