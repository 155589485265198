/**
* Created by ZJ on 2021/12/9 10:45.
*/
<template>
  <div class="base-content">
    <form-frame :reset-visible="false" :submit-visible="false">
      <div slot="content">
        <query-frame
          :current="pageNo"
          :total="total"
          :addVisible="false"
          @on-query="handleQuery"
          @on-reset="handleReset"
          @on-page-change="handlePageChange"
          @on-size-change="handleSizeChange"
        >
          <template slot="btns">
            <a-button type="primary" icon="download" @click="exportexcel()">
              导出</a-button
            >
          </template>
          <!-- 查询条件开始 -->
          <template slot="query">
            <query-item label="会员手机号">
              <a-input v-model="mobile">
                <a-icon slot="prefix" type="phone" />
              </a-input>
            </query-item>
            <query-item label="生效日期">
              <a-date-picker @change="onChangestart" format="YYYY-MM-DD"></a-date-picker>
            </query-item>
            <query-item label="失效日期">
              <a-date-picker @change="onChangeend" format="YYYY-MM-DD"></a-date-picker>
            </query-item>
          </template>
          <!-- 查询条件结束 -->
          <!-- 查询结果 开始 -->
          <template slot="table">
            <i-table :columns="columns" :dataSource="dataList"> </i-table>
          </template>
          <!-- 查询结果 结束 -->
        </query-frame>
      </div>
    </form-frame>
  </div>
</template>

<script>
import {reportExport} from '@/api/coupon';
import moment from 'moment';
import FormFrame from '@/components/frame/FormFrame.vue';
import queryMixin from '@/mixin/queryMixin';
import { isEmpty, deepClone } from '@/libs/utils';
import IDatePicker from '../../components/IDatePicker.vue';
import { getAction, deleteAction } from '@/api/manage';

export default {
  name: 'RecordList',
  mixins: [queryMixin],
  components: {
    FormFrame,
    IDatePicker,
  },
  props: {},
  data() {
    const startDate = moment().subtract(365, 'days').format('YYYY-MM-DD');
    const endDate = moment(new Date()).add(365, 'days').format('YYYY-MM-DD');
    return {
      query: {
        duration: [startDate, endDate],
      },
      actionData: {},
      payVisible: false,
      cancelVisible: false,
      cancelLoading: false,
      cancelReason: '',
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          },
        },
        {
          title: '会员姓名',
          dataIndex: 'realName',
          key: 'realName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '会员昵称',
          dataIndex: 'nickName',
          key: 'nickName',
          ellipsis: true,
        },
        {
          title: '手机',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
        },
        {
          title: '优惠券',
          dataIndex: 'couponName',
          key: 'couponName',
          ellipsis: true,
        },
        {
          title: '生效日期',
          dataIndex: 'startTime',
          key: 'startTime',
          ellipsis: true,
        },
        {
          title: '失效日期',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
        },
        {
          title: '优惠券类型',
          dataIndex: 'types',
          key: 'types',
          ellipsis: true,
          width: 120,
          customRender: (text, record, index) => {
            const typeDict = ['体验券', '优惠券', '转赠券', '礼包', '充值券'];
            return typeDict[+(text - 1)];
          },
        },
        {
          title: '使用情况',
          dataIndex: 'used',
          key: 'used',
          ellipsis: true,
          customRender: (text, record, index) => {
            const typeDict = ['未使用', '已使用'];
            return typeDict[+text];
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 260,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.deleteItem(row)}
                >
                  删除
                </a-button>
              </section>
            );
          },
        },
      ],
      url: {
        list: '/api/trd/trdCoupon/MemberCouponlist',
        export: '/trd/trdCoupon/ExportMemberCoupon',
        delete: '/api/trd/trdCouponPerson/delete',
      },
      dataList: [],
      currentNode: {},
      isEditCoupon: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      mobile: '',
      startTime: '',
      endTime: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
    // console.log(moment().subtract(0, 'days').format('YYYY-MM-DD 23:59:59'))
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取列表
    getData() {
      const data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        mobile: this.mobile,
        endTime: this.endTime,
        startTime: this.startTime
      };
      getAction(this.url.list, data).then((res) => {
        const { total, records } = res;
        this.dataList = records;
        this.total = total;
      });
    },
    onChangeend(date, dateString) {
      this.endTime = dateString;
      console.log('end', dateString);
    },
    onChangestart(date, dateString) {
      this.startTime = dateString;
      console.log('start', dateString);
    },
    // exportexcel() {
    //   // const url = `${window._CONFIG.domianURL}${this.url.export}`;
    //   window.open(url);
    // },
    exportexcel() {
      // if (this.query.duration && this.query.duration.length) {
      //   this.params.startDate = this.query.duration[0];
      //   this.params.endDate = this.query.duration[1];
      // }
      // if(this.query.shopIds && this.query.shopIds.length){
      //   this.params.shopIds = this.query.shopIds;
      // }
      // console.log(this.params)
      reportExport().then((data) => {
        window.open(data);
        // window.location.href = data;
      });
    },
    handlePageChange(page) {
      this.pageNo = page;
      this.getData();
    },
    handleSizeChange(current, pageSize) {
      this.pageNo = 1;
      this.pageSize = pageSize;
      this.getData();
    },
    deleteItem(row) {
      const that = this;
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中优惠券?',
        onOk() {
          const iurl = `${that.url.delete}?id=${row.id}`;
          deleteAction(iurl)
            .then((res) => {
              that.getData();
            })
            .catch((error) => {
              that.$message.warning(error);
            })
            .finally(() => {});
        },
      });
    },
    // 添加
    handleAdd() {},
    handleReset() {
      this.mobile = '';
      this.startTime = '';
      this.endTime = '';
    },
    handleQuery() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
